import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import CurrentSweeps from './currentSweeps';
import { CheckTicketResponse } from '../enter/EntryResponseMatrix';
import GamePlay from '../reveal/gamePlay';

import './style.scss';
import PostClaimBox from './postclaim_box';

import promotion from '../../../promotion_config';
import { getText } from '../../utils/cms_copy_provider';

const PostClaimInner = ({ entry, config, revealToken, actions, location }) => {
    const [revealEntries, setRevealEntries] = useState(true);

    const onNewTicket = (e) => {
        e.preventDefault();

        actions.entryActions.reset();
        actions.drawTicketActions.reset();
        actions.scratchTicketActions.reset();
        actions.fastPlayTicketActions.reset();

        if (location.state.returnURL) {
            navigate(location.state.returnURL);
        } else {
            navigate('/enter');
        }
    };

    const bonusAwardedFilter = (bonus) => bonus.result && bonus.result === 'awarded';

    const showEntry = () => {
        const awardedBonuses = entry.last_entry.Bonus ? entry.last_entry.Bonus.filter(bonusAwardedFilter) : [];

        if (awardedBonuses.length) {
            const imgUrl =
                (config.config.image_host &&
                    (config.config.image_host.startsWith('https://') ? config.config.image_host : `https://${config.config.image_host}`)) ||
                '';

            return awardedBonuses.map((bonus) => {
                return bonus.rewards.map((reward) => {
                    if (reward.reward) {
                        const resolvedCurrency = promotion.resolveCurrency(reward.reward.currency, getText);

                        return (
                            <PostClaimBox
                                imageUrl={`${imgUrl}/pa/promotion_${reward.reward.promotion_id}_thumb.png`}
                                name={bonus.promotionname}
                                currency={reward.reward.currency}
                                prize_name={!resolvedCurrency.isPence ? reward.reward.prize_name : ''}
                                amount={resolvedCurrency.resolve(reward.reward.actual_amount)}
                                config={config}
                                reward={reward.reward}
                                call_to_action={() => {
                                    return <CurrentSweeps key={reward.reward.reward_id} sweepId={reward.reward.sku} />;
                                }}
                            />
                        );
                    } else {
                        return null;
                    }
                });
            });
        }
    };

    function handleRevealEntries(newValue) {
        setRevealEntries(newValue);
    }

    if (entry.last_entry) {
        const lent = entry.last_entry;
        const awardedBonuses = entry.last_entry.Bonus ? entry.last_entry.Bonus.filter(bonusAwardedFilter) : [];

        const showReveal = () => {
            if (awardedBonuses.length) {
                return awardedBonuses.map((bonus) => {
                    if (bonus.reveal.length > 0 && revealToken) {
                        const reveals = bonus.reveal;

                        // pick a reveal from the array of available reveals
                        const randomReveal = reveals[Math.floor(Math.random() * reveals.length)];

                        const outcomeData = {
                            awards: [],
                        };
                        outcomeData.awards.push(...bonus.rewards[0].awards);

                        return (
                            <GamePlay
                                gameServer={config.config.reveal_host}
                                gameId={randomReveal}
                                token={revealToken.revealToken}
                                outcomeData={outcomeData}
                                showEntries={handleRevealEntries}
                            />
                        );
                    }
                });
            }
        };
        return (
            <div className='post-claim primary-page-content container-fluid'>
                <div className='row'>
                    <div className='col-md-10 offset-md-1'>
                        <div className='description pb-3'>
                            {lent?.TicketDetails?.TicketStatus?.toUpperCase() !== 'DUPLICATE' &&
                            ((lent?.Bonus && !lent.Bonus.length) || lent?.Bonus === null) ? (
                                <CheckTicketResponse
                                    ticketCheckInfo={{ ...lent.TicketDetails, TicketStatus: 'NOT_AVAILABLE' }}
                                    ticketResponseCode={lent.ResultCode}
                                />
                            ) : lent?.TicketDetails?.TicketStatus || lent?.ResultCode === 504 ? ( // explicit check for 504 applies to PA only, this feels like it should be in the EntryResponseMatrix.js however
                                <CheckTicketResponse ticketCheckInfo={lent.TicketDetails} ticketResponseCode={lent.ResultCode} />
                            ) : (
                                <CheckTicketResponse
                                    ticketCheckInfo={{ ...lent.TicketDetails, TicketStatus: 'UNKNOWN' }}
                                    ticketResponseCode={lent.ResultCode}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div id='entry-result-container' className={`row ${revealEntries ? '' : ' hidden'}`}>
                    <div className='col-10 offset-1'>{showEntry()}</div>
                </div>
                <div className='row'>
                    <div className='col'>{showReveal()}</div>
                </div>
                <div className={`row ${revealEntries ? '' : ' hidden'}`}>
                    <div className='col-md-4 offset-md-4 enter-another'>
                        <a
                            className='my-4 theme-btn'
                            href={location.state && location.state.returnURL ? location.state.returnURL : '/enter'}
                            onClick={onNewTicket}>
                            ENTER ANOTHER TICKET
                        </a>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='post-claim'>
                <div className='row'>
                    <div className='col-md-4 offset-md-4'>
                        <a className='mt-4 theme-btn' href='/enter'>
                            ENTER A TICKET
                        </a>
                    </div>
                </div>
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PostClaimInner);
