import React from 'react';

import pluralize from 'pluralize';
import { titleCase } from 'title-case';
import CouponDisplay from '../coupon/coupon_display';
import PromotionLink from './promotion_link';

const PostClaimBox = ({ imageUrl, name, currency, prize_name, amount, call_to_action, config, reward }) => {
    return (
        <>
            <div className='media my-2 text-center claim-box' key={name}>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <PromotionLink urlBase={config?.config?.promotion_uri} name={name}>
                                <img className='img-fluid align-self-center promo-image' src={`${imageUrl}`} alt={`${name} logo`} />
                            </PromotionLink>
                        </div>
                        <div className='col-md-6'>
                            <div className='media-body promotion-info-container'>
                                <p className='mt-1 reward-amount'>{amount}</p>
                                <p className='mt-1 reward-currency'>{pluralize(titleCase(prize_name), amount, false)}</p>
                                <p className='mt-1 sweeps-cta'>
                                    {currency === 'lotteryproducts' ? (
                                        <CouponDisplay
                                            prize_name={reward.prize_name}
                                            prize_description={reward.prize_description}
                                            entry_data={reward.entry_data}
                                            redeem={reward.redeem}
                                            expiration={reward.expiration}
                                            state={reward.state}
                                            Url={reward.Url}
                                            barcodeHost={config?.config?.barcode_image_host}
                                            linkText='View Coupon'
                                            expiredText='Expired Coupon'
                                            redeemedText='Redeemed'
                                            failedText='Reward pending. Please check back later.'
                                        />
                                    ) : (
                                        call_to_action()
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PostClaimBox;
