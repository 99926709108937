import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../components/layouts/Layout';
import SEO from '../components/seo';

import '../components/postclaim/style.scss';
import PostClaimInner from '../components/postclaim/post_claim_inner';

export const PostClaim = ({ user, entry, config, notification, revealToken, actions, location }) => {
    return (
        <Layout user={user} config={config} notification={notification} actions={actions}>
            <SEO title='PostClaim' />
            <PostClaimInner location={location} />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PostClaim);
