// This component is meant to return a link wrapping the component's children
// that will be based on the URI in the frontend config and a slug created from
// the promotion's name.
//
// Required parameters --
// urlBase (from the config), name (promotion name), and children (being wrapped by the link)

import React from 'react';
import slugify from 'react-slugify';

const PromotionLink = ({ urlBase, name, children }) => {
    // do not wrap the image in a link if it is falsey at all
    // empty strings, null, undefined, etc. would all create
    // broken links
    if (!urlBase) {
        return <>{children}</>;
    } else {
        const promoUrl = urlBase + '/' + slugify(name);
        return (
            <a href={promoUrl} rel='noopener noreferrer'>
                {children}
            </a>
        );
    }
};

export default PromotionLink;
